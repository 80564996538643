window.cafe = window.cafe || {};
/**
 * 공통
 * 네비게이션바 컨트롤러
 * cafe_type3 - 개별카페
 * @singleton
 */
(function () {
	window.CafeNavigationBarController = {
		type: null,
		$navigationEl: null,
		naviBarHeight: 0,
		isAnimate: false,
		lastScrollTop: 0,
		delta: 50,
		init: function () {
			this.$navigationEl = jQuery("#daumHead").find(".cafe_head");
			var $navigationBar = jQuery("#daumHead").find(".wrap_tit")
			var $cafeToolbar = jQuery(".cafe_type3 #daumHead .cafe_navi");

			if ($navigationBar.length && $cafeToolbar.length) {
				this.naviBarHeight = $navigationBar.height();

				var self = this;
				var startPosY = 0;
				var endPosY = 0;

				jQuery(window).scroll(function (ev) {
					self.changeNavigationBarPosition(ev);
				});
			}
		},
		changeNavigationBarPosition: function (ev) {
			var daumWrap = jQuery("#daumWrap");
			if (daumWrap.hasClass("wrap_control")) {
				return;
			}

			var currentScrollTop = jQuery(window).scrollTop();

			if (Math.abs(this.lastScrollTop - currentScrollTop) <= this.delta) {
				return;
			}

			if (currentScrollTop > this.lastScrollTop) { // scroll down
				// bounce
				if (currentScrollTop < 35) {
					return;
				}

				CafeProfileViewController.hideProfile();
			} else {	// scroll up
				var posTop = parseInt(this.$navigationEl.css("top"));

				if (posTop == -this.naviBarHeight) {
					if (!this.isAnimate) {
						this.isAnimate = true;
						var self = this;

						this.$navigationEl.animate(
							{"top": 0},
							200,
							function () {
								self.isAnimate = false;
							}
						);
					}
				}
			}
			this.lastScrollTop = currentScrollTop;
		},
		setLastScrollTop: function (scrollTop) {
			this.lastScrollTop = scrollTop;
		}
	};

	CafeNavigationBarController.init();
})();

/**
 * 카페탑
 * 추천 컨텐츠 슬라이더
 * @singleton
 */
(function () {
	var $targetEl, $prevBtn, $nextBtn, cafeTopContentsSlide = null, items;

	this.cafeRecommendSlider = {
		init: function (config) {
			jQuery.extend(this, config);
			setValues.call(this);
			if ($targetEl.length && this.items.length) {
				createSlide();
			}
		},
		slide: function () {
			return cafeTopContentsSlide;
		}
	};

	var setValues = function () {
		$targetEl = jQuery(this.targetId);
		$prevBtn = jQuery(this.prevBtnId);
		$nextBtn = jQuery(this.nextBtnId);
		items = this.items;
	};

	var createSlide = function () {
		var newSlider = generateSlidePage.call(this);
		cafeTopContentsSlide = new this.slide.Slide($targetEl[0], newSlider);
		setPagingAction();
		setPagingEvents();
	};

	var generateSlidePage = function () {
		var randomPage = Math.floor(Math.random() * 3);
		var newSlider = new this.slide.InfiniteDataSource(generateSlideData(items));
		newSlider.setCurrentIndex(randomPage);
		setSlidePage(randomPage);
		return newSlider;
	};

	var generateSlideData = function (items) {
		var list = [];
		for (var i = 0, len = items.length; i < len; i++) {
			list.push(buildSlideItem(items[i].title, items[i].contents, items[i].linkUrl, items[i].imageUrl, i + 1));
		}
		return list;
	};

	var setPagingAction = function () {
		cafeTopContentsSlide.on("prev", function () {
			setSlidePage(cafeTopContentsSlide.dataSource.index);
		});

		cafeTopContentsSlide.on("next", function () {
			setSlidePage(cafeTopContentsSlide.dataSource.index);
		});
	};

	var setPagingEvents = function () {
		$prevBtn.on("click", function () {
			cafeTopContentsSlide.prev();
		});

		$nextBtn.on("click", function () {
			cafeTopContentsSlide.next();
		});
	};

	var convertToMobileUrl = function (url) {
		var BASEURL = "https://cafe.daum.net";
		if (url.indexOf(BASEURL) > -1) {
			var mUrl = url + "#profile";
			return mUrl.replace("cafe.daum.net", "m.cafe.daum.net");
		}
		return url;
	};

	var buildSlideItem = function (title, contents, linkUrl, imageUrl, index) {
		var mobile_url = convertToMobileUrl(linkUrl);
		var item = {
			title: title,
			contents: contents,
			linkUrl: mobile_url,
			imageUrl: imageUrl,
			toHTML: function () {
				var str = '<span class="thumb_slide" style="background-image:url(' + imageUrl + ');background-position:50% 0"></span>'
					+ '<div class="desc_slide #today_recommend_cafe"><a href="' + mobile_url + '" class="link_slide #recommand_cafe_0' + index + '"><div class="inner_slide"><span class="tit_slide">추천카페</span>'
					+ '<span class="txt_slide"><span class="sr_only">카페명</span>' + title + '</span>'
					+ '<em class="emph_slide">' + contents + '</em>'
					+ '</div></a></div>';
				return str;
			}
		};
		return item;
	};

	var setSlidePage = function (curPage) {
		jQuery(".inner_paging .num_page").each(function (index) {
			if (index == curPage) {
				jQuery(this).addClass("num_page--on").html("현재페이지 " + (index + 1));
			} else {
				jQuery(this).removeClass("num_page--on").html(index + 1);
			}
		});
	};
})();

/**
 * 카페탑, 개별카페
 * 검색 컨트롤러
 * @singleton
 * @required - type("search" | "result")
 */
window.CafeSearchController = {
	viewType: "search",
	searchButton: null,
	searchLayer: null,
	searchForm: null,
	input: null,
	resetButton: null,
	closeButton: null,
	init: function (config) {
		if (config) {
			this.viewType = config.viewType;

			// common feature
			this.input = jQuery(config.inputId);
			this.resetButton = jQuery(config.resetId);
			this.closeButton = jQuery(config.closeId);

			// view type action
			if (this.viewType == "search") {
				this.totalSearch = config.totalSearch;
				this.searchButton = jQuery(config.searchId);
				this.searchLayer = jQuery(config.layerId);
				this.searchForm = jQuery(config.searchFormId);

				// set return url
				jQuery(".box_search .input_return_value").val(document.URL);
			} else if (this.viewType == "result") {
				this.setClearButton();
				this.input.val(this.input.val());
			}

			this.addEventListener();

			if (config.suggest) {
				this.initSuggest(config.suggest);
			}
		}
	},
	initSuggest: function (suggestConfig) {
		var serviceType = suggestConfig.serviceType === 'cafe' ? 'cafe' : suggestConfig.serviceType === 'table' ? 'table' : '';
		new window.suggest.Suggest(document.querySelector(suggestConfig.searchLayer), {
			inputEl: suggestConfig.inputEl, suggestBoxEl: suggestConfig.suggestBoxEl, APIServer: {
				host: 'https://suggest.cafe.daum.net/suggest/' + serviceType,
				queries: {
					'limit': 20
				}
			}
		});
		$('head').append('<style>.screen_out{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}</style>');

		this.focusWhenSearchLayerClicked(suggestConfig);
		this.submitWhenItemClicked(suggestConfig);
	},
	focusWhenSearchLayerClicked: function (suggestConfig) {
		$(suggestConfig.searchLayer).on('click', suggestConfig.searchInnerLayer, function () {
			$(suggestConfig.inputEl).focus();
		});
	},
	submitWhenItemClicked: function (suggestConfig) {
		$('#' + suggestConfig.suggestBoxEl).on('click', '.list li', function () {
			setTimeout(function () {
				$(suggestConfig.searchForm).submit();
			}, 0);
		});
	},
	addEventListener: function () {
		var self = this;

		// common feature
		this.input.on('change paste keyup', function () {
			self.setClearButton();
		});

		this.resetButton.on('click', function (e) {
			self.input.val('');
			self.input.focus();
			self.offClearButton();
		});

		// view type action
		if (this.viewType == "search") {
			this.searchButton.on("click", jQuery.proxy(this.show, this));
			this.closeButton.on("click", jQuery.proxy(this.hide, this));

			this.searchForm.on("submit", function () {
				if (self.input.val().trim().length == 0) {
					alert("검색어를 입력해 주세요.");
					return false;
				}
			});
		} else if (this.viewType == "result") {
			this.input.on("focus", function () {
				jQuery("#daumWrap").addClass("wrap_control");
			});

			this.input.on("blur", function () {
				jQuery("#daumWrap").removeClass("wrap_control");
			});

			jQuery(".inner_schall").on("submit", function () {
				if (self.input.val().trim().length == 0) {
					alert("검색어를 입력해 주세요.");
					return false;
				}
			});

			this.closeButton.on('click', function (e) {
				var returnUrl = Utils.getParam('r');

				if ('' == returnUrl) {
					window.history.back();
				} else {
					window.location.href = returnUrl;
				}
			});

			jQuery('#serviceType').on('change', function () {
				var url = document.URL;

				if (url.indexOf("page=") > 0) {
					url = url.replace(/page=(.+?)(&|$)/, "page=1");
				}

				if (url.indexOf("serviceType=") > 0) {
					window.location.href = url.replace(/serviceType=(.+?)(&|$)/, "serviceType=" + jQuery('#serviceType').val() + "&");
				} else {
					window.location.href = url + "&serviceType=" + jQuery('#serviceType').val();
				}
			});

			jQuery('#sortType').on('change', function () {
				var url = document.URL;

				if (url.indexOf("page=") > 0) {
					url = url.replace(/page=(.+?)(&|$)/, "page=1");
				}

				if (url.indexOf("sort=") > 0) {
					window.location.href = url.replace(/sort=(.+?)(&|$)/, "sort=" + jQuery('#sortType').val() + "&");
				} else {
					window.location.href = url + "&sort=" + jQuery('#sortType').val();
				}
			});

			jQuery('#articleHead').on('change', function () {
				var url = document.URL;
				if (url.indexOf("head=") > 0) {
					window.location.href = url.replace(/head=(.*?)(&|$)/, "head=" + jQuery('#articleHead').val() + "&");
				} else {
					window.location.href = url + "&head=" + jQuery('#articleHead').val();
				}
			});

			jQuery("#selectedName").text(jQuery(".opt_select option:selected").text());
		}

		var shouldKeyboardHide = function (focusedInput, targetInput) {
			var KEYBOARD_HIDE_SCROLL_POSITION = 30;
			var scrollTop = jQuery(window).scrollTop();

			return (typeof focusedInput != 'undefined' && scrollTop >= KEYBOARD_HIDE_SCROLL_POSITION && focusedInput.attr('id') == targetInput.attr('id'));
		};

		jQuery(window).scroll(function () {
				var focusedInput = jQuery('input:focus');

				if (shouldKeyboardHide(focusedInput, self.input)) {
					focusedInput.blur();
				}
			}
		);
	},
	show: function () {
		var len = this.totalSearch.length;
		for (var i = 0; i < len; i++) {
			jQuery(this.totalSearch[i]).hide();
		}

		this.searchLayer.show();
		this.input.focus();
		$('#daumWrap').addClass('cafe_search');

		this.fixIOSFocusBug();
		return false;
	},
	fixIOSFocusBug: function () {
		var self = this;
		setTimeout(function () {
			self.input.focus();
		}, 0);
	},
	hide: function () {
		var len = this.totalSearch.length;
		for (var i = 0; i < len; i++) {
			jQuery(this.totalSearch[i]).show();
		}
		// 카페탑의 슬라이드 버그 대응
		if (typeof cafeRecommendSlider != 'undefined' && cafeRecommendSlider.slide()) {
			cafeRecommendSlider.slide().resize();
		}

		this.searchLayer.hide();
		$('#daumWrap').removeClass('cafe_search');
	},
	setClearButton: function () {
		if ('' == this.input.val()) {
			this.offClearButton();
		} else {
			this.onClearButton();
		}
	},
	onClearButton: function () {
		jQuery(".box_search").addClass('write_on');
	},
	offClearButton: function () {
		jQuery(".box_search").removeClass('write_on');
	}
};

/**
 * 개별카페
 * 카페 프로필 컨트롤러
 * @singleton
 *
 */
window.CafeProfileViewController = {
	daumWrap: undefined,
	daumHead: undefined,
	daumContent: undefined,
	profileService: "#profile",
	isInit: false,
	init: function () {
		if (this.isInit) {
			return;
		}
		this.daumWrap = jQuery("#daumWrap");
		this.daumHead = jQuery('#daumHead');
		this.daumContent = jQuery('#daumContent');

		jQuery("#btn_cafe_profile").on("click", this.toggleProfile.bind(this));
		this.openProfile();
		this.triggerResize();
		this.isInit = true;
	},
	triggerResize: function () {
		window.dispatchEvent(new Event('resize'));
	},
	toggleProfile: function () {
		CafeNavigationBarController.setLastScrollTop(jQuery(window).scrollTop());
		if (this.daumWrap.is('.profile_on')) {
			this.hideProfile();
			return;
		}
		this.showProfile();
	},
	setContentsMarginTop: function () {
		if (!this.daumWrap.is('.mobile_entrance')) {
			return;
		}
		var DEFAULT_PROFILE_HEIGHT = 160;
		var CURRENT_BANNER_BASE_WIDTH = Math.min(this.daumWrap.width(), 456);
		var BANNER_RATIO = 124 / 456;
		var PAGINATION_HEIGHT = this.daumHead.find('.has-pagination').length > 0 ? 20 : 0;
		var FIXED_GNB_HEIGHT = 45;
		var FANCAFE_PROFILE_HEIGHT_GAP = this.daumWrap.is('.fancafe_profile_on') ? 110 : 0;

		this.daumContent.css('margin-top', DEFAULT_PROFILE_HEIGHT + (CURRENT_BANNER_BASE_WIDTH * BANNER_RATIO) + PAGINATION_HEIGHT + FIXED_GNB_HEIGHT + FANCAFE_PROFILE_HEIGHT_GAP + 'px');
	},
	showProfile: function () {
		this.daumWrap.addClass('profile_on');
		this.setContentsMarginTop();
		this.triggerResize();
		sessionStorage.setItem('currentGrpcode', CAFEAPP.GRPCODE);
		sessionStorage.setItem('cafeInfoOpened', true);
	},
	hideProfile: function () {
		if (!this.isInit) {
			this.init();
		}
		this.removeProfileParam();
		this.daumWrap.removeClass('profile_on');
		this.daumContent.prop('style', '');
		this.triggerResize();
		sessionStorage.setItem('currentGrpcode', CAFEAPP.GRPCODE);
		sessionStorage.setItem('cafeInfoOpened', false);
	},
	showProfileWhenReload() {
		let isOpen = true;
		const currentGrpcode = sessionStorage.getItem('currentGrpcode');

		if (currentGrpcode === CAFEAPP.GRPCODE) {
			isOpen = sessionStorage.getItem('cafeInfoOpened') === 'true';
		} else {
			isOpen = CAFEAPP.IS_FANCAFE === 'true' || CAFEAPP.IS_GAMECAFE === 'true';
		}

		if (isOpen) {
			const animatedComponents = jQuery('.d_head, .d_head *, .d_visual, .d_visual *');
			animatedComponents.addClass('noanimation');
			this.showProfile();
			setTimeout(() => {
				animatedComponents.removeClass('noanimation');
			}, 400);
		}

		this.removeProfileParam();
	},
	removeProfileParam: function () {
		var loc = window.location.href;
		if (loc.indexOf(this.profileService) > 0) {
			history.replaceState("", "", loc.replace(this.profileService, ""));
		}
	},
	removeProfileOpenInfo() {
		sessionStorage.removeItem('currentGrpcode');
		sessionStorage.removeItem('cafeInfoOpened');
	},
	openProfile: function () {
		const isFanCafe = CAFEAPP.IS_FANCAFE === 'true';
		const isGameCafe = CAFEAPP.IS_GAMECAFE === 'true';
		const hasProfileHash = location.hash.substring(1) === 'profile';

		if (isFanCafe) {
			const apiCaller = jQuery(cafe.fancafe.apiCaller);
			apiCaller.on('fancafe_info_generate', () => {
				apiCaller.off();

				if (cafe.fancafe.fancafeInfo.cafe.useCheerWidget) {
					this.showProfileWhenReload();
				}
			});
		} else if (isGameCafe || hasProfileHash) {
			this.showProfileWhenReload();
		}
	}
};

/**
 * 방문한 카페 관리
 * 탑 > 내카페 정렬, 카페 진입시 저장 기능
 * @singleton
 *
 */
window.VisitCafeManager = {
	MAX_DATA: 30,
	storage: jQuery.localStorage,
	key: function () {
		return 'VISIT_CAFE_' + Utils.getUserid();
	},
	add: function (grpid) {
		if (grpid) {
			this.init();

			var data = this.get();
			var index = data.indexOf(grpid);

			if (0 == index) {
				return;
			} else if (-1 < index) {
				data.splice(index, 1);
			}

			data.unshift(grpid);

			if (data.length > this.MAX_DATA) {
				while (data.length > this.MAX_DATA) {
					data.pop();
				}
			}

			this.storage.set(this.key(), data);
		}
	},
	get: function () {
		this.init();
		return this.storage.get(this.key());
	},
	isEmpty: function () {
		return this.storage.isEmpty(this.key());
	},
	init: function () {
		if (this.isEmpty()) {
			this.storage.set(this.key(), '[]');
		}
	},
	reorderCafeByRecentVisit: function () {
		var visitCafesOldOrder = this.get().reverse();
		var allCafeList = jQuery("#allCafeList");

		for (var i in visitCafesOldOrder) {
			var grpid = visitCafesOldOrder[i];
			var item = allCafeList.find("li[data-grpid*='" + grpid + "']");
			if (0 != item.size()) {
				allCafeList.find("li[data-grpid*='" + grpid + "']").remove();
				allCafeList.prepend(item);
			}
		}

		allCafeList.show();
	}
};

/**
 * 즐겨찾는 기능 부모 클래스
 * @Object
 */
window.Favorite = {};

/**
 * 즐겨찾는 카페
 * 카페탑 > 내카페탭
 * @singleton
 *
 */
window.Favorite.Cafe = {
	init: function () {
		this.updateFavoriteCount();
		this.registerEventListener();
	},
	registerEventListener: function () {
		var self = this;

		jQuery(document).on('click', '[data-name="favorite"]', function (e) {
			var currentButton = jQuery(this);
			var grpid = currentButton.parent().data().grpid;

			if (currentButton.find('.ico-favorite--on').length > 0) {
				self.remove(grpid);
			} else {
				self.add(grpid);
			}
		});
	},
	add: function (grpid) {
		jQuery.ajax({
			url: '/_myCafe/' + grpid,
			type: 'PUT',
			data: {isGrpcode: "N"},
			success: function (result) {
				if ('success' == result.status) {
					var current = jQuery("li[data-grpid='" + grpid + "']");
					current.find('[data-name="favorite"] .ico-favorite').addClass('ico-favorite--on');
					current.find('[data-name="favorite"] .sr_only').text('즐겨찾기 별 선택됨');
					var favCafeList = jQuery('#favCafeList');
					favCafeList.append(current.clone());
					Favorite.Cafe.updateFavoriteCount();
				} else if ('overflow' == result.status) {
					alert("즐겨찾는 카페는 최대 30개까지 설정 가능합니다.\n기존 즐겨찾는 카페를 해제한 후 설정해 주세요.");
				} else {
					alert("즐겨찾는 카페 설정중 오류가 발생했습니다. 다시 시도 해주세요.");
				}
			}
		});
	},
	remove: function (grpid) {
		if (!confirm("즐겨찾는 카페로 설정해 놓으면 더 빠르게 카페로 이동할 수 있습니다.\n\n설정을 해제하시겠습니까?")) {
			return;
		}

		jQuery.ajax({
			url: '/_myCafe/' + grpid,
			type: 'DELETE',
			success: function (result) {
				if ('success' == result.status) {
					var current = jQuery("li[data-grpid='" + grpid + "']");
					current.find('[data-name="favorite"] .ico-favorite').removeClass('ico-favorite--on');
					current.find('[data-name="favorite"] .sr_only').text('즐겨찾기 별 선택안됨');
					var favCafeList = jQuery('#favCafeList');
					favCafeList.find('[data-grpid="' + grpid + '"]').remove();
					Favorite.Cafe.updateFavoriteCount();
				} else {
					alert("즐겨찾는 카페 해제중 오류가 발생했습니다. 다시 시도 해주세요.");
				}
			}
		});
	},
	updateFavoriteCount: function () {
		var size = jQuery('#favCafeList').children().size();

		if (0 == size) {
			jQuery('#favCafeSize').text('');
			jQuery('#favCafeList').parent().hide();
			jQuery('#empty_favorite_cafe').show();
		} else {
			jQuery('#favCafeSize').text(size);
			jQuery('#favCafeList').parent().show();
			jQuery('#empty_favorite_cafe').hide();
		}
	}
};

/**
 * 개별카페 - 글복사 금지 설정
 */
(function () {
	var startX, startY, tap;
	var longpress = 1000, startT;
	var articleBase = jQuery('#article');

	var cancel = function (e) {
		if (window.event) {
			window.event.cancelBubble = true;
			window.event.returnValue = false;
		}
		if (e && e.stopPropagation && e.preventDefault) {
			e.stopPropagation();
			e.preventDefault();
		}
		return false;
	};
	var block = function (e) {
		e = e || window.event;
		var t = e.srcElement || e.target;
		var tag = t.tagName;
		if (e && tag === 'HTML' || tag === 'INPUT' || tag === 'TEXTAREA' || tag === 'BUTTON' || tag === 'SELECT' || tag === 'OPTION' || tag === 'EMBED' || tag === 'OBJECT' || tag === 'AUDIO') {
			return;
		}
		if (e.type === 'keydown' || e.type == 'keyup') {
			// keyboard event : only block ctrl-A, ctrl-a, ctrl-C, ctrl-c, meta-A, meta-a, meta-C, meta-c
			if ((e.ctrlKey || e.metaKey) && (e.keyCode == 65 || e.keyCode == 97 || e.keyCode == 67 || e.keyCode == 99)) {
				return cancel(e);
			}
		} else if (e.type == "contextmenu") {
			alert('무단복사를 막기 위해\n마우스 드래그 금지가 설정되어 있습니다');
			return cancel(e);
		}
	};
	var addEvent = function (el, type, fn) {
		if (window.addEventListener) {
			el.addEventListener(type, fn, false);
		} else if (window.attachEvent) {
			el.attachEvent('on' + type, fn);
		} else {
			el['on' + type] = fn;
		}
	};
	var addBlockEvent = function () {
		addEvent(document.body, 'keydown', block);
		addEvent(document.body, 'keyup', block);
		addEvent(document.body, 'mouseup', block);
		addEvent(document.body, 'mousedown', block);
		addEvent(document.body, 'dragstart', block);
		addEvent(document.body, 'selectstart', block);
		addEvent(document.body, 'copy', block);
		addEvent(document.body, 'contextmenu', block);
	};

	this.setCopyNotAllowed = function () {
		removeLinksOnImages();
		setNoneIOSEvent();
		setEvents();
		addBlockEvent();
	};

	var removeLinksOnImages = function () {
		articleBase.find('img').each(function () {
			var $parent = $(this).parent();
			var imglinks = $parent.attr('href');
			if (this.src.indexOf(imglinks) > -1) {
				$parent.removeAttr('href');
			}
		});
	};

	var setEvents = function () {
		articleBase.bind('taphold', function (ev) {
			ev.preventDefault();
		}).on('touchstart', function (ev) {
			getStartData(ev);
		}).on('touchend', function (ev) {
			if (isLongPressed(ev)) {
				ev.preventDefault();
				alert('복사 금지된 글입니다');
			}
			startT = 0;
			setTap();
		}).on('contextmenu', function (ev) {
			ev.preventDefault();
			alert('복사 금지된 글입니다');
		});
	};

	var setNoneIOSEvent = function () {
		var isiDevice = /ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase());
		if (!isiDevice) {
			window.oncontextmenu = function (event) {
				event.preventDefault();
				event.stopPropagation();
				return false;
			};
		}
	};
	var getStartData = function (ev) {
		startT = new Date().getTime();
		startX = getCoord(ev, 'X');
		startY = getCoord(ev, 'Y');
	};

	var isLongPressed = function (ev) {
		return Math.abs(getCoord(ev, 'Y') - startY) < 5 && new Date().getTime() >= (startT + longpress);
	};

	var getCoord = function (e, c) {
		return /touch/.test(e.type) ? (e.originalEvent || e).changedTouches[0]['page' + c] : e['page' + c];
	};

	var setTap = function () {
		tap = true;
		setTimeout(function () {
			tap = false;
		}, 700);
	};
}());

/**
 * 로딩 인디케이터
 * @singleton
 * @required - targetId
 *
 */
window.LoadingIndicator = {
	loadingView: null,
	targetEl: null,
	isMoreLoading: false,
	init: function (targetId) {
		this.targetEl = jQuery(targetId);

		if (this.targetEl.length) {
			this.targetEl.css("position", "relative");
			this.isMoreLoading = true;
		}
		this.generateView();
	},
	generateView: function () {
		var loadingTemplate = '<div id="loadingLayer" class="loading_layer">'
			+ '<span class="loading_img">로딩중</span>'
			+ '</div>';
		this.loadingView = jQuery(loadingTemplate);
		this.loadingView.hide();

		if (this.isMoreLoading) {
			this.targetEl.append(this.loadingView);
		} else {
			jQuery("#daumWrap").append(this.loadingView);
		}
	},
	show: function (targetId) {
		if (!this.loadingView) {
			this.init(targetId);
		}
		this.loadingView.show();
	},
	hide: function () {
		if (this.loadingView) {
			this.loadingView.hide();
		}
	}
};

/**
 * 유틸리티
 * @singleton
 *
 */
window.Utils = {
	getUserid: function () {
		return ((typeof jQuery.cookie('HM_CU') !== 'undefined') ? (jQuery.cookie('HM_CU').substr(parseInt(jQuery.cookie('HM_CU')[0]) + 1)) : 'NOLOGIN');
	},
	isLogin: function () {
		return this.getUserid() !== 'NOLOGIN';
	},
	getParam: function (name) {
		return decodeURIComponent(
			(RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [, ''])[1]
		);
	},
	getParamValue(name) {
		return Utils.urlParameter[name] || null;
	},
	urlParameter: (() => {
		let ret = {};
		const queries = location.search.substr(1).split('&').map(s => s.split('='));

		queries.forEach(v => {
			ret[v[0]] = v[1];
		});

		return ret;
	})(),
	login: function (url) {
		const returnUrl = url || document.URL;
		window.location.href = CAFEAPP.LOGIN_URL + '/accounts/loginform.do?mobilefull=1&url=' + returnUrl;
	},
	logout: function (url) {
		const returnUrl = url || document.URL;
		window.location.href = CAFEAPP.LOGIN_URL + '/accounts/logout.do?url=' + returnUrl;
	},
	goPcUrl: function (url) {
		jQuery.cookie("M_P2M", "N", {
			domain: ".daum.net",
			path: "/"
		});
		document.location.href = url;
	},
	unescapeHTML: function (s) {
		s = s.replace(/<br\s?\/?>/gi, '\r\n');
		return s.replace(/<\w+(\s+("[^"]*"|'[^']*'|[^>])+)?>|<\/\w+>/gi, '').replace(/&lt;/g, '<')
			.replace(/&gt;/g, '>').replace(/&amp;/g, '&')
			.replace(/&quot;/g, '"').replace(/&#34;/g, '"').replace(/&#39;/g, "'").replace(/&apos;/g, "'");
	},
	limitInputText: function (inputElId, bytesElId, maxSize) {
		var text = jQuery(inputElId).val().trim();
		var tempStr = "";
		var bytes = 0;

		for (var i = 0; i < text.length; i++) {
			var cbyte = 0.5;
			if (escape(text.charAt(i)).length > 4) {
				cbyte = 1;
			}
			bytes += cbyte;
			if (bytes > maxSize) {
				alert("한글 " + maxSize + "자까지 입력 가능합니다.\n초과된 글자는 자동으로 삭제됩니다.");
				jQuery(inputElId).val(text.substring(0, i));
				bytes -= cbyte;
				break;
			}
		}
		jQuery(bytesElId).text(Math.round(bytes));
	},
	getStringBytes: function (str) {
		var text = str.trim();
		var bytes = 0;

		for (var i = 0; i < text.length; i++) {
			var cbyte = 0.5;
			if (escape(text.charAt(i)).length > 3) {
				cbyte = 1;
			}
			bytes += cbyte;
		}
		return bytes;
	},
	cutStringBytes: function (str, limitBytes) {
		var text = str.trim();
		var bytes = 0;

		for (var i = 0; i < text.length; i++) {
			var cbyte = 0.5;
			if (escape(text.charAt(i)).length > 4) {
				cbyte = 1;
			}
			bytes += cbyte;

			if (bytes > limitBytes) {
				return text.substring(0, i);
			}
		}
		return text;
	},
	realname: function () {
		window.location.href = CAFEAPP.MEMBER_URL + '/m/namecheck.daum?svcCode=2&rtnUrl=' + document.URL;
	},
	isMobile: function () {
		return (/android|iphone|ios|ipad|blackberry|opera|mini|windows\sce|palm|smartphone|iemobile/i.test(navigator.userAgent.toLowerCase()));
	},
	isAndroid: function () {
		return (/android/i.test(navigator.userAgent.toLowerCase()));
	},
	isiPhone: function () {
		return (/iphone|ipod/i.test(navigator.userAgent.toLowerCase()));
	},
	isiPad: function () {
		return (/ipad/i.test(navigator.userAgent.toLowerCase()));
	},
	isWindowsPhone: function () {
		return (/iemobile/i.test(navigator.userAgent.toLowerCase()));
	},
	getAndroidVersion: function () {
		var match = navigator.userAgent.match(/Android\s([0-9\.]*)/);
		return match ? match[1] : 'unknown';
	},
	isGingerbread: function () {
		if (Utils.isAndroid()) {
			if (Utils.getAndroidVersion().indexOf("2.3.") > -1) {
				return true;
			}
		}
		return false;
	},
	isSafari() {
		return !/Chrome|Firefox/.test(navigator.userAgent) && /Safari/.test(navigator.userAgent);
	},
	web2app: function (url_scheme, onUnsuppertedCallback, willInvokeCallback) {
		if (!this.isMobile()) {
			window.location.href = 'https://cafe.daum.net/_app';
			return;
		}

		var android_scheme = "";
		if (this.isAndroid) {
			if (url_scheme) {
				android_scheme = url_scheme.replace("daumcafe://", "");
			} else {
				android_scheme = "open";
			}
		}
		var appStoreURL = this.isAndroid() ? "http://durl.me/4xuhfg" : "http://itunes.apple.com/kr/app/id369302790?mt=8&t__nil_gnbdownload=iosapp";
		daumtools.web2app({
			urlScheme: url_scheme ? url_scheme : "daumcafe://",
			intentURI: "intent://" + android_scheme + "#Intent;scheme=daumcafe;package=net.daum.android.cafe;end",
			storeURL: appStoreURL,
			appName: '카페앱',
			onUnsupportedEnvironment: function () {
				if (typeof onUnsuppertedCallback != "undefined") {
					onUnsuppertedCallback();
				}
			},
			willInvokeApp: function () {
				if (typeof willInvokeCallback != "undefined") {
					willInvokeCallback();
				}
			}
		});
	},
	activateInputBox: function (elementId, targetId) {
		var inputEl = jQuery(elementId);
		var targetEl = jQuery(targetId);

		if (inputEl.length && targetEl.length) {
			inputEl.on("focus", function () {
				targetEl.addClass("write_on");
			});

			inputEl.on("blur", function () {
				targetEl.addClass("remove_on");
			});
		}
	},
	goRandomCategoryView: function () {
		var categoryList = ["1", "2", "3", "4", "5", "6", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "o", "P", "Q"];
		var index = Math.floor((Math.random() * 10) + 1);
		document.location.href = "/_midCategory?cateid=" + categoryList[index];
	},
	goWriteView: function (traget) {
		if (window.pageConfig && window.pageConfig.boardType == "C" && jQuery.trim(jQuery('#content').val()) != "") {
			if (!window.confirm("한줄 메모장에 작성한 내용이 있습니다.\n통합 글쓰기로 이동하시겠습니까?")) {
				return;
			}
		}

		if (this.urlParameter.returnURL) {
			location.href = traget.href;
		} else {
			location.href = traget.href + ((traget.href.indexOf("?") == -1 ? "?" : "&") + "returnURL=" + encodeURIComponent(location.href));
		}
	},
	sendCampaignApp: function (options) {
		var appName = options.appName;
		var urlScheme = options.urlScheme;
		var storeUrl = makeCampaignStoreUrl(urlScheme, options.androidStoreUrl, options.iosStoreUrl, options.te2, options.ct);
		daumtools.web2app({
			urlScheme: urlScheme,
			intentURI: options.intentURI,
			storeURL: storeUrl,
			useUrlScheme: true,
			appName: appName,
			onUnsupportedEnvironment: function () {
				window.location.href = 'https://m.search.daum.net/search?w=tot&DA=BESE&q=' + appName;
			}
		});

		function makeCampaignStoreUrl(urlScheme, androidStoreUrl, iosStoreUrl, te2, ct) {
			var storeUrl;
			var marketRefererParam;
			var teCode;

			var isAndroid = Utils.isAndroid();

			if (isAndroid) {
				storeUrl = androidStoreUrl;
				teCode = te2.android;
			} else {
				storeUrl = iosStoreUrl + '?ct=' + ct;
				teCode = te2.ios;
			}

			marketRefererParam = 'te1=campaign&te2=' + teCode + '&te3=web2app&ruid=ruid&';
			storeUrl += '&referrer=' + encodeURIComponent(marketRefererParam);

			if (isAndroid) {
				storeUrl += '&url=' + encodeURIComponent(urlScheme);
			}

			return storeUrl;
		}
	},
	getThumbnail(url, width, height, cropOption) {
		return url.match(/sandbox\.kakaocdn\.net/)
			? `//img1-sandbox.kakaocdn.net/thumb/${cropOption}${width}x${height}/?fname=${url}`
			: `//img1.daumcdn.net/thumb/${cropOption}${width}x${height}/?fname=${url}`;
	},
	isGameSDK() {
		return navigator.userAgent.includes('GameSDK');
	},
	loadCachedScript(url, options) {
		options = jQuery.extend( options || {}, {
			dataType: 'script',
			cache: true,
			url: url
		});
		return jQuery.ajax(options);
	}
};

/**
 * 자동등업 안내 레이어
 * @singleton
 *
 */
(function () {
	window.levelupNotiLayer = {
		cookieName: 'lvup',
		deleteCookie: function (cookieName) {
			var expireDate = new Date();
			expireDate.setDate(expireDate.getDate() - 1);
			document.cookie = cookieName + "= " + "; expires=" + expireDate.toGMTString() + "; path=/";
		},
		init: function () {
			setTimeout(function () {
				var lvup = cafe.util.getCookie(levelupNotiLayer.cookieName);
				if (!lvup) {
					return;
				}

				var jsonLvup = JSON.parse(lvup);
				var message = "";
				var detail = "";

				if (jsonLvup.levelupType == '1') {
					if (jsonLvup.torolecodename.length > 5) {
						message = jsonLvup.torolecodename + '<br>으로 등업하기 위한';
					} else {
						message = jsonLvup.torolecodename + '으로 등업하기 위한';
					}
					if (jsonLvup.actionType == 'HV') {
						detail = '방문일 (' + jsonLvup.count + '/' + jsonLvup.count + ')<br>활동완료!';
					} else if (jsonLvup.actionType == 'NC') {
						detail = '새 댓글 (' + jsonLvup.count + '/' + jsonLvup.count + ')<br>활동완료!';
					} else if (jsonLvup.actionType == 'NA') {
						detail = '새글수 (' + jsonLvup.count + '/' + jsonLvup.count + ')<br>활동완료!';
					} else {
						detail = '';
					}

				} else {
					message = '축하드려요~';
					if (jsonLvup.torolecodename.length > 5) {
						detail = jsonLvup.torolecodename.substring(0, 5) + '<br>' + jsonLvup.torolecodename.substring(5) + '으로<br>등업되셨어요!';
					} else {
						detail = jsonLvup.torolecodename + '으로<br>등업되셨어요!';
					}
				}

				var layerTemplate = '<div id="levelupNotiLayer" class="cafe_layer level_layer">'
					+ '<div class="inner_cafe_layer"><div class="layer_body">'
					+ '<span class="detail_cafe">' + message + '</span>'
					+ '<strong class="tit_cafe">' + detail + '</strong>'
					+ '<span class="ico_level ico_level' + jsonLvup.levelupType + '"></span></div>'
					+ '<div class="layer_foot"><button type="button" id="levelupNotiLayerCloseBtn" class="btn_close"><span class="ico_cafe ico_close">닫기</span>&nbsp;</button></div>'
					+ '</div></div>';
				var layer = jQuery(layerTemplate);
				jQuery("#daumWrap").append(layer);
				layer.show();

				jQuery("#levelupNotiLayerCloseBtn").on("click", function () {
					layer.hide();
				});

				levelupNotiLayer.deleteCookie(levelupNotiLayer.cookieName);
			}, 1000);
		}
	};
	levelupNotiLayer.init();
})();

/**
 * 안내 레이어 > 탑 - 내소식, 개별 - 게시판목록
 * @singleton
 *
 */
window.guideLayer = {
	key_prefix: 'GUIDE_',
	key: null,
	frame: null,
	close: null,
	expireDay: null,
	init: function (key, frame, webToApp, close, expireDay) {
		this.key = key;
		this.frame = jQuery(frame);
		this.webToApp = jQuery(webToApp);
		this.close = this.frame.find(close);
		this.expireDay = expireDay;

		this.checkAndShow();
	},
	getKey: function () {
		return this.key_prefix + this.key;
	},
	checkAndShow: function () {
		if (!jQuery.cookie(guideLayer.getKey())) {
			this.frame.show();

			this.close.on('click', function () {
				jQuery.cookie(guideLayer.getKey(), 'CLOSED', {expires: guideLayer.expireDay});
				guideLayer.frame.hide();
			});
		}
	}
};

/**
 * Web2App 안내 레이어
 * @singleton
 *
 */
window.web2AppGuideLayer = {
	init: function (config) {
		this.layer = jQuery(config.layerId);

		if (this.layer.length) {
			var self = this;
			jQuery(config.buttonId).on("click", function () {
				if (self.layer.is(":visible")) {
					self.layer.hide();
				} else {
					self.layer.show();
				}
			});

			this.layer.on("click", ".link_go", function () {
				Utils.web2app(config.schemeUrl);
				self.layer.hide();
			});

			this.layer.on("click", ".btn_close", function () {
				self.layer.hide();
			});

			jQuery(".cafe_layer .btn_close").on("click", function () {
				jQuery("#cafeWeb2AppLayer").hide();
			});
		}
	}
};

/**
 * 일부 단말에 한글 작성시 keyup 이벤트 안 먹히는 경우가 있어서 0.5초 간격으로 강제로 keyup 발생하는 유틸
 *
 */
window.Observe = function (oEl) {
	this._o = oEl;
	this._value = oEl.value;
	this._bindEvents();
};

Observe.prototype._bindEvents = function () {
	var self = this;
	var bind = function (oEl, sEvent, pHandler) {
		if (oEl.attachEvent) {
			oEl.attachEvent('on' + sEvent, pHandler);
		} else {
			oEl.addEventListener(sEvent, pHandler, false);
		}
	};

	bind(this._o, 'focus', function () {
		if (self._timer) {
			clearInterval(self._timer);
		}
		self._timer = setInterval(function () {
			if (self._value != self._o.value) {
				self._value = self._o.value;
				self._fireEvent();
			}
		}, 50);

	});

	bind(this._o, 'blur', function () {
		if (self._timer) {
			clearInterval(self._timer);
		}
		self._timer = null;
	});
};

Observe.prototype._fireEvent = function () {
	if (document.createEvent) {
		var e;

		if (window.KeyEvent) {
			e = document.createEvent('KeyEvents');
			e.initKeyEvent('keyup', true, true, window, false, false, false, false, 65, 0);
		} else {
			e = document.createEvent('UIEvents');
			e.initUIEvent('keyup', true, true, window, 1);
			e.keyCode = 65;
		}

		this._o.dispatchEvent(e);
	} else {
		var e = document.createEventObject();
		e.keyCode = 65;
		this._o.fireEvent('onkeyup', e);
	}
};

window.Captcha = function (captchaImage) {
	this.captchaImage = captchaImage;
	this.captchaImage.onload = function () {
	};

	this.imageUrl = captchaImage.src;
	this.soundUrl = "/Captcha.mp3";

	this.wrapper = daum.$$("div.inner_send", document.body)[0];
	this.captchaInput = daum.$$("#guesscaptcha", this.wrapper)[0];
	this.captchaInputBeforeValue = '';
	this.reloadButton = daum.$$(".btn_captcha_viewer", this.wrapper)[0];
	this.playButton = daum.$$(".btn_captcha_player", this.wrapper)[0];

	if (daum.$$("label.lab_write", this.wrapper).length > 0) {
		this.checkMessage = daum.$$("label.lab_write", this.wrapper)[0];
	} else {
		this.checkMessage = document.createElement('label');	// 마크업에 없을경우를 대비
	}
	this.init();
};

window.Captcha.correctedAnswer = 'false';
window.Captcha.prototype = {
	init: function () {
		this.captchaPlayerInit();
		this.initInputElementEvent();
	},

	captchaPlayerInit: function () {
		daum.Event.addEvent(this.playButton, 'click', this.playSecureSound.bindAsEventListener(this));
	},

	initInputElementEvent: function () {
		daum.Event.addEvent(this.captchaInput, 'keyup', this.check.bind(this));
		daum.Event.addEvent(this.reloadButton, 'click', this.showSecureImage.bindAsEventListener(this));
	},

	showSecureImage: function (ev) {
		daum.Event.preventDefault(ev);
		this.captchaImage.src = this.imageUrl + "?dummy=" + new Date().getTime();
		return false;
	},

	playSecureSound: function (ev) {
		daum.Event.preventDefault(ev);
		playCaptcha(this.soundUrl);
		return false;
	},

	check: function () {
		if (this.captchaInput.value.length >= 4 && daum.String.trim(this.captchaInput.value) !== this.captchaInputBeforeValue) {
			new daum.Ajax({
				paramString: "guesscaptcha=" + this.captchaInput.value.toUpperCase(),
				method: "POST",
				onsuccess: function (res) {
					Captcha.correctedAnswer = res.responseText;
					daum.Element.addClassName(this.checkMessage, "txt_point");
					if (Captcha.correctedAnswer === 'true') {
						this.checkMessage.innerHTML = "보안문자를 정확히 입력하셨습니다.";

					} else if (Captcha.correctedAnswer === 'false') {
						this.checkMessage.innerHTML = "보안문자를 잘못 입력하셨습니다.";
					} else if (Captcha.correctedAnswer === 'timeout') {
						alert("죄송합니다. 보안문자 유효시간이 만료되어 보안문자를 변경하였습니다.\n다시 입력해 주세요.");
						this.showSecureImage();
						this.captchaInput.value = '';
						this.checkMessage.innerHTML = "보안문자 유효시간이 만료되어 보안문자를 변경하였습니다.";
					} else if (Captcha.correctedAnswer === 'error') {
						alert("죄송합니다. 보안문자 확인이 실패하여 문자를 변경하였습니다.");
						this.showSecureImage();
						this.captchaInput.value = '';
						this.checkMessage.innerHTML = "보안문자 확인이 실패하여 보안문자를 변경하였습니다."
					}
				}.bind(this),
				onfailure: function (res) {
				}
			}).request("/captcha_check_correction");
		} else if (this.captchaInput.value.length == 0) {
			this.checkMessage.innerHTML = "보안문자를 정확하게 입력해주세요.";
		}
		this.captchaInputBeforeValue = this.captchaInput.value;
	}
};

window.captchaSend = function () {
	if (Captcha.correctedAnswer == 'true') {
		document.authinfo.submit();
	} else {
		return false;
	}
};

window.playCaptcha = function (srcUrl) {
	var audio = daum.$("captchaAudio");
	if (Utils.isAndroid()) {
		audio = daum.$("captchaVideo");
	}
	var support = audio.canPlayType('audio/mpeg;codecs="mp3"');
	if (support != "") {
		setAudioPlayer(audio, srcUrl);
		var callback = function () {
			audio.play();
			audio.removeEventListener('canplaythrough', callback);
		};
		audio.addEventListener('canplaythrough', callback);
	} else {
		if (audio.tagName.toLowerCase() == 'video') {
			setAudioPlayer(audio, srcUrl);
			audio.play();
		} else {
			alert("보안문자 음성재생이 지원되지 않는 브라우저를 사용중 이십니다.");
		}
	}
};

window.setAudioPlayer = function (audio, srcUrl) {
	if (daum.Browser.android) {
		audio.src = srcUrl + "?CCSCK=" + daum.Browser.getCookie("CCSCK");
	} else {
		audio.src = srcUrl + "?dummy=" + new Date().getTime();
	}
	audio.load();
};

window.socialShare = {
	arrSnsTypes: ["kakaotalk", "twitter", "facebook", "kakaogroup", "mail", "url", "kakaostory"],
	getTitle: null,
	init: function (config) {
		this.setConfig(config);
		this.splitAndReunitUri(config);
		for (var i = 0, l = this.arrSnsTypes.length; i < l; i++) {
			config.arrSnsUrls[this.arrSnsTypes[i]] = this.articleUri + this.checkParameters() + this.arrSnsTypes[i];
		}
		if (config.svc === "kakaotalkTab" || config.svc === "daumapp") {
			this.loadChannelType(config);
		}
	},
	setConfig: function (config) {
		config.title = $("meta[property='og:title']").attr('content');
		config.image = $("meta[property='og:image']").attr('content');
		config.url = document.location.href;
		config.arrSnsUrls = [];
	},
	checkParameters: function () {
		return this.articleUri.indexOf('?') > -1 ? "&sns=" : "?sns=";
	},
	splitAndReunitUri: function (config) {
		var urlsplit = config.url.split("/");
		if (urlsplit[2].indexOf("cafe.daum.net") > -1) {
			this.articleUri = "https://cafe.daum.net/" + urlsplit[3] + "/" + urlsplit[4] + "/" + urlsplit[5];
		} else {
			this.articleUri = window.location.href.split("#")[0];
		}
	},
	loadChannelType: function (config) {
		$.ajax({
			url: 'https://developers.kakao.com/sdk/js/kakao.min.js',
			dataType: "script",
			success: function () {
				Kakao.init(Utils.kakaoAPIKey, Utils.kakaoAPIExtraKey);
			}
		});

		function shareKakaotalk() {
			Kakao.Link.sendScrap({
				requestUrl: config.arrSnsUrls['kakaotalk']
			});
		}

		function shareFacebook() {
			window.location.href = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(config.arrSnsUrls['facebook']);
		}

		function shareTwitter() {
			window.location.href = 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(config.title) + '&url=' + encodeURIComponent(config.arrSnsUrls['twitter']);
		}

		function shareKakaoStory() {
			if (!Utils.isMobile()) {
				alert('모바일에서만 가능합니다.');
				return;
			}

			Kakao.Story.open({
				url: config.arrSnsUrls['kakaostory'],
				text: config.title
			});
		}

		$('.view_sns a.link_kt').on('click', shareKakaotalk);
		$('.view_sns a.link_fb').on('click', shareFacebook);
		$('.view_sns a.link_ks').on('click', shareKakaoStory);
		$('.view_sns a.link_tw').on('click', shareTwitter);
	}
};

(function ($) {
	$('#footLinkTop').click(function (e) {
		e.preventDefault();
		$(window).scrollTop(0);
	});

	$('#footLinkApp').click(function (e) {
		e.preventDefault();
		Utils.web2app();
	})
})(jQuery);

(function ($) {
	var daumWrap = $('#daumWrap');

	daumWrap.on('click', '.make-return-uri', function () {
		if (cafe.util.isStorageAvailable(sessionStorage)) {
			sessionStorage.setItem('returnURI', location.href);
		}
	});

	daumWrap.on('click', '#button_return', function (e) {
		e.preventDefault();
		if (cafe.util.isStorageAvailable(sessionStorage) && sessionStorage.getItem('returnURI') !== null) {
			var returnURI = sessionStorage.getItem('returnURI');
			sessionStorage.removeItem('returnURI');
			cafe.util.locationAssign(returnURI);
			return;
		}
		cafe.util.prevLocation(CAFEAPP.CONTEXT_PATH.replace('comments', ''));
	});

	daumWrap.on('click', '.make-list-uri', function () {
		if (cafe.util.isStorageAvailable(sessionStorage)) {
			sessionStorage.setItem('listURI', location.href);
		}
	});

	daumWrap.on('click', '.button-return-list', function (e) {
		e.preventDefault();
		if (cafe.util.isStorageAvailable(sessionStorage) && sessionStorage.getItem('listURI') !== null) {
			var listURI = sessionStorage.getItem('listURI');
			sessionStorage.removeItem('listURI');
			cafe.util.locationAssign(listURI);
			return;
		}
		cafe.util.locationAssign('/' + CAFEAPP.GRPCODE + '/' + CAFEAPP.FLDID + '?boardType=' + CAFEAPP.BOARDTYPE);
	});
})(jQuery);

(function () {
	var cafeBannerWrap;
	var bannerTemplate = '' +
		'<div class="cafe-banner">' +
		'	<div style="background:url({bannerBg}) repeat-x">' +
		'		<a href="{bannerLink}" class="cafe-banner__link">' +
		'			<img class="cafe-banner__image" src="{bannerImage}" width="304" height="48" alt="{altmessage}">' +
		'		</a>' +
		'	</div>' +
		'</div>';

	var displayBanner = function (cardInfo) {
		var banner = bannerTemplate.replace(/{bannerLink}/, cardInfo.bannerLink)
			.replace(/{bannerBg}/, cardInfo.bannerBg)
			.replace(/{bannerImage}/, cardInfo.bannerImage)
			.replace(/{altmessage}/, cardInfo.altmessage);
		cafeBannerWrap.append(banner).show();
	};

	var getBannerInfo = function (fileName) {
		return $.ajax({
			type: 'GET',
			url: '//t1.daumcdn.net/cafe_cj/mobileweb/' + fileName + '?_=' + parseInt(new Date().getTime() / 300000),
			timeout: 500,
			dataType: 'json'
		});
	};

	cafe.cafeTopBanner = {
		init: function (wrapSelector) {
			cafeBannerWrap = $(wrapSelector);
			return cafe.cafeTopBanner;
		},
		display: function (fileName) {
			getBannerInfo(fileName).done(function (bannerInfo) {
				if (!bannerInfo.applying) {
					return;
				}

				var randomIndex = Math.floor(Math.random() * bannerInfo.bannerList.length);
				var selectedBanner = bannerInfo.bannerList[randomIndex];
				displayBanner(selectedBanner);
			});
		}
	};
})(jQuery);

(function ($) {
	cafe.notification = cafe.notification || {};

	var networkError = function () {
		alert('네트워크 오류. 잠시뒤에 시도해 주세요.');
	};

	var getKeywordNotificationList = function (grpid, fldid) {
		grpid = grpid || '';
		fldid = fldid || '';
		return $.ajax({
			type: 'GET',
			contentType: 'application/json; charset=utf-8',
			url: '/_keyword/list?grpid=' + grpid + '&fldid=' + fldid,
			error: networkError
		});
	};

	var removeKeywordNotification = function (keywordId) {
		var data = {id: keywordId};
		return $.ajax({
			type: 'POST',
			contentType: 'application/json; charset=utf-8',
			url: '/_keyword/remove',
			data: JSON.stringify(data),
			error: networkError
		});
	};

	var registerKeywordNotification = function (registerKeywordParam) {
		return $.ajax({
			type: 'POST',
			contentType: 'application/json; charset=utf-8',
			url: '/_keyword/register',
			data: JSON.stringify(registerKeywordParam)
		}).fail(function (error) {
			if (!!error.responseJSON && error.responseJSON.description) {
				alert(error.responseJSON.description.slice(7));
				return;
			}
			networkError();
		});
	};

	var registerFavoriteFolder = function (registerFavoriteParam) {
		var formData = '' +
			'grpcode=' + encodeURIComponent(registerFavoriteParam.grpcode) +
			'&grpname=' + encodeURIComponent(Utils.unescapeHTML(registerFavoriteParam.grpname)) +
			'&fldname=' + encodeURIComponent(Utils.unescapeHTML(registerFavoriteParam.fldname)) +
			'&fldtype=' + encodeURIComponent(registerFavoriteParam.fldtype);

		return $.ajax({
			type: 'POST',
			url: '/_favoriteFolder/' + registerFavoriteParam.grpid + '/' + registerFavoriteParam.fldid,
			data: formData,
			error: networkError
		}); // old api
	};

	var removeFavoriteFolder = function (removeFavoriteFolderParam) {
		return $.ajax({
			type: 'DELETE',
			contentType: 'application/json; charset=utf-8',
			url: '/_favoriteFolder/' + removeFavoriteFolderParam.grpid + '/' + removeFavoriteFolderParam.fldid,
			error: networkError
		})
	};

	var registerNewArticleNotification = function (newArticleNotificationParam) {
		return $.ajax({
			type: 'PUT',
			contentType: 'application/json; charset=utf-8',
			url: '/_favoriteFolder',
			data: JSON.stringify(newArticleNotificationParam),
			error: networkError
		});
	};

	var suggestKeyword = function (keyword) {
		return $.ajax({
			type: 'GET',
			contentType: 'application/json; charset=utf-8',
			url: '/_keyword/suggest?word=' + encodeURIComponent(keyword),
			error: networkError
		});
	};

	var getBoardList = function (grpcode) {
		return $.ajax({
			type: 'GET',
			contentType: 'application/json; charset=utf-8',
			url: '/_keyword/boards?grpcode=' + grpcode,
			error: function (error) {
				if (error.responseJSON.description === "MCAFE_LONG_TIME_NO_VISIT") {
					if (confirm('해당 카페에 접속한지 오래되어 인증이 필요합니다. 해당 카페로 이동하시겠습니까?')) {
						cafe.util.locationAssign('/' + grpcode);
					}
					return;
				}
				networkError();
			}
		});
	};

	var getMyCafeList = function () {
		return $.ajax({
			type: 'GET',
			contentType: 'application/json; charset=utf-8',
			url: '/_mycafes',
			error: networkError
		});
	};

	var deleteNotification = function (deleteNotificationParam) {
		return $.ajax({
			type: 'POST',
			contentType: 'application/json; charset=utf-8',
			url: '/_mynews/remove',
			data: JSON.stringify(deleteNotificationParam),
			error: networkError
		});
	};
	
	var deleteTableActivityNotification = function (tableAlimId) {
		return $.ajax({
			type: 'DELETE',
			url: '/_mynews/table/' + tableAlimId,
			error: networkError
		});
	}

	cafe.notification.apiCaller = (function () {
		return {
			getKeywordNotificationList: getKeywordNotificationList,
			removeKeywordNotification: removeKeywordNotification,
			registerKeywordNotification: registerKeywordNotification,
			registerFavoriteFolder: registerFavoriteFolder,
			removeFavoriteFolder: removeFavoriteFolder,
			registerNewArticleNotification: registerNewArticleNotification,
			suggestKeyword: suggestKeyword,
			getBoardList: getBoardList,
			getMyCafeList: getMyCafeList,
			deleteNotification: deleteNotification,
			deleteTableActivityNotification: deleteTableActivityNotification,
		}
	})();
})(jQuery);

(function ($) {
	var confirmToRemoveFavoriteFolder = function () {
		return confirm('즐겨찾는 게시판을 해제하겠습니까?\n해제 시 새글알림도 받아볼 수 없습니다.');
	};

	cafe.notification.cafeBoardPresenter = (function () {
		var update = function () {
			var favBoardSize = $('#favBoardSize');
			var boardList = $('#boardList');
			var favBoardList = $('#favBoardList');
			var favBoardListItems = boardList.find('.ico-favorite--on').closest('li');
			var size = favBoardListItems.size();

			if (size <= 0) {
				favBoardList.empty();
				favBoardList.parent().hide();
				favBoardSize.text(size);
				return;
			}

			if (size !== favBoardList.children().length) {
				favBoardList.empty();
				favBoardList.append(favBoardListItems.clone());
				favBoardSize.text(size);
				favBoardList.find('.more').removeClass('more');
				favBoardList.find('.ico-rounded-checked').removeClass('ico-rounded-checked');
			}

			favBoardList.parent().show();
		};

		var confirmToRemoveFavoriteFolderInCafe = function (favoriteButton) {
			if (favoriteButton.next().find('.ico-notification--small-on').length > 0) {
				return confirmToRemoveFavoriteFolder();
			}
			return true;
		};

		return {
			update: update,
			confirmToRemoveFavoriteFolder: confirmToRemoveFavoriteFolderInCafe,
		}
	})();

	cafe.notification.myCafeBoardPresenter = (function () {
		var removeUnFavoriteBoard = function () {
			$('.ico-favorite').each(function (index, element) {
				var actionElement = $(element).parent();

				if (!$(element).hasClass('ico-favorite--on')) {
					actionElement.parent().remove();
				}
			});
		};

		var update = function () {
			removeUnFavoriteBoard();

			var size = $('.ico-favorite--on').length;
			var favBoardSize = $('#favBoardSize');
			var favBoardList = $('#favBoardList');
			var favBoardEmpty = $('#favBoardEmpty');

			if (size <= 0) {
				favBoardSize.text('');
				favBoardList.hide();
				favBoardEmpty.show();
				return;
			}

			favBoardSize.text(size);
			favBoardList.show();
			favBoardEmpty.hide();
		};

		return {
			update: update,
			confirmToRemoveFavoriteFolder: confirmToRemoveFavoriteFolder,
		}
	})();
})(jQuery);

(function ($) {
	var boardPresenter;

	var getAlimOptionBy = function (fldid) {
		return $('[data-fldid="' + fldid + '"]');
	};

	var setNewArticleOn = function (fldid) {
		var alimOptions = getAlimOptionBy(fldid);
		alimOptions.prev().find('.ico-favorite').addClass('ico-favorite--noti-on');
		alimOptions.find('[data-name="notification"] .ico-notification--small').addClass('ico-notification--small-on');
		alimOptions.find('[data-name="notification"]').find('.sr_only').html('설정 됨');
	};

	var setNewArticleOff = function (fldid) {
		var alimOptions = getAlimOptionBy(fldid);
		alimOptions.prev().find('.ico-favorite').removeClass('ico-favorite--noti-on');
		alimOptions.find('[data-name="notification"] .ico-notification--small-on').removeClass('ico-notification--small-on');
		alimOptions.find('[data-name="notification"]').find('.sr_only').html('설정 안됨');
	};

	var setFavoriteOn = function (fldid) {
		var alimOptions = getAlimOptionBy(fldid);
		alimOptions.prev().find('.ico-favorite').addClass('ico-favorite--on');
		alimOptions.find('[data-name="favorite"] .ico-favorite').addClass('ico-favorite--on');
		alimOptions.find('[data-name="favorite"]').find('.sr_only').html('설정 됨');
	};

	var setFavoriteOff = function (fldid) {
		var alimOptions = getAlimOptionBy(fldid);
		alimOptions.prev().removeClass('more');
		alimOptions.prev().find('.ico-favorite').removeClass('ico-favorite--noti-on ico-favorite--on ico-rounded-checked');
		alimOptions.find('[data-name="favorite"]').find('.sr_only').html('설정 안됨');
		alimOptions.find('[data-name="notification"] .ico-notification--small-on').removeClass('ico-notification--small-on');
		alimOptions.find('[data-name="notification"]').find('.sr_only').html('설정 안됨');
	};

	var registerFavoriteFolderSuccess = function (fldid, result) {
		if (result.status === 'success') {
			setFavoriteOn(fldid);
			boardPresenter.update();
			return;
		}

		if (result.status === 'overflow') {
			alert('즐겨찾는 게시판은 100개까지\n\n등록할 수 있습니다.');
			return;
		}

		alert('즐겨찾는 게시판 등록중 오류가 발생했습니다. 다시 시도 해주세요.');
	};

	var registerFavoriteFolder = function (registerFavoriteFolderParam) {
		registerFavoriteFolderParam.grpid = registerFavoriteFolderParam.grpid || CAFEAPP.GRPID || '';
		registerFavoriteFolderParam.grpcode = registerFavoriteFolderParam.grpcode || CAFEAPP.GRPCODE || '';
		registerFavoriteFolderParam.grpname = registerFavoriteFolderParam.grpname || CAFEAPP.GRPNAME || '';
		cafe.notification.apiCaller.registerFavoriteFolder(registerFavoriteFolderParam)
			.done(registerFavoriteFolderSuccess.bind(cafe.notification.notificationSetting, registerFavoriteFolderParam.fldid));
	};

	var makeRegisterFavoriteFolderParam = function (alimSettingButton) {
		return alimSettingButton.next().data();
	};

	var alimSettingButtonEventBinding = function (myCafeList) {
		myCafeList.on('click', '.my-cafe__action, .cafe-board__action', function (e) {
			var alimSettingButton = $(e.currentTarget);
			alimSettingButton.toggleClass('more');
			alimSettingButton.find('.ico-favorite').toggleClass('ico-rounded-checked');

			if (!alimSettingButton.find('.ico-favorite').hasClass('ico-favorite--on')) {
				registerFavoriteFolder(makeRegisterFavoriteFolderParam(alimSettingButton));
			}
		});
	};

	var removeFavoriteFolderSuccess = function (fldid, result) {
		if (result.status === 'success') {
			setFavoriteOff(fldid);
			boardPresenter.update();
			return;
		}

		alert('즐겨찾는 게시판 해제중 오류가 발생했습니다. 다시 시도 해주세요.');
	};

	var removeFavoriteFolder = function (removeFavoriteFolderParam) {
		removeFavoriteFolderParam.grpid = removeFavoriteFolderParam.grpid || CAFEAPP.GRPID || '';
		cafe.notification.apiCaller.removeFavoriteFolder(removeFavoriteFolderParam)
			.done(removeFavoriteFolderSuccess.bind(cafe.notification.notificationSetting, removeFavoriteFolderParam.fldid));
	};

	var makeRemoveFavoriteFolderParam = function (favoriteButton) {
		return favoriteButton.parent().data();
	};

	var favoriteButtonEventBinding = function (myCafeList) {
		myCafeList.on('click', '[data-name="favorite"]', function (e) {
			var favoriteButton = $(e.currentTarget);

			if (favoriteButton.find('.ico-favorite--small-on').length > 0 && boardPresenter.confirmToRemoveFavoriteFolder(favoriteButton)) {
				removeFavoriteFolder(makeRemoveFavoriteFolderParam(favoriteButton));
			}
		});
	};

	var updateAlimElement = function (fldid) {
		var notiyn = getAlimOptionBy(fldid).find('.ico-notification--small-on').length > 0;
		if (!notiyn) {
			setNewArticleOn(fldid);
			return;
		}

		setNewArticleOff(fldid);
	};

	var registerNewArticleSuccess = function (fldid, result) {
		if (result.status === 'success') {
			updateAlimElement(fldid);
			boardPresenter.update();
			return;
		}

		alert('새글 알림 설정중 오류가 발생했습니다. 다시 시도 해주세요.');
	};

	var registerNewArticleNotification = function (newArticleNotificationParam) {
		cafe.notification.apiCaller.registerNewArticleNotification(newArticleNotificationParam)
			.done(registerNewArticleSuccess.bind(cafe.notification.notificationSetting, newArticleNotificationParam.fldid));
	};

	var makeNewArticleNotificationParam = function (dataAttribute, newArticleButton) {
		return {
			grpid: dataAttribute.grpid || CAFEAPP.GRPID || '',
			fldid: dataAttribute.fldid,
			notiyn: (newArticleButton.hasClass('on')) ? 'N' : 'Y'
		};
	};

	var newArticleNotificationButtonEventBinding = function (myCafeList) {
		myCafeList.on('click', '[data-name="notification"]', function (e) {
			var newArticleButton = $(e.currentTarget);
			var dataAttribute = newArticleButton.parent().data();
			registerNewArticleNotification(makeNewArticleNotificationParam(dataAttribute, newArticleButton));
		});
	};

	var eventBinding = function () {
		var myCafeList = $('.my-cafe ul, .cafe-board ul');
		alimSettingButtonEventBinding(myCafeList);
		favoriteButtonEventBinding(myCafeList);
		newArticleNotificationButtonEventBinding(myCafeList);
	};

	var init = function (brdPrsntr) {
		eventBinding();
		boardPresenter = brdPrsntr;
	};

	cafe.notification.notificationSetting = (function () {
		return {
			init: init,
			registerFavoriteFolder: registerFavoriteFolder
		}
	})();
})(jQuery);

(function ($) {
	var MAX_COUNT = '500+';
	var alimCount, listUl, emptyStateEl;

	var cacheElements = function (options) {
		alimCount = $(options.alimCountSelector);
		listUl = $(options.newsListSelector);
		emptyStateEl = $(options.emptyStateWrapSelector);
	};

	var eventBinding = function () {
		listUl.on('change', function (e) {
			if (alimCount.text() === MAX_COUNT) {
				return;
			}

			var alimListLength = $(e.currentTarget).find('li').length;
			alimCount.html(alimListLength);
			if (alimListLength === 0) {
				emptyStateEl.addClass('empty');
			}
		});
	};

	var init = function (options) {
		cacheElements(options);
		eventBinding();
	};

	cafe.notification.countModule = (function () {
		return {
			init: init
		}
	})();
})(jQuery);

(function ($) {
	var myNewsList;
	var makeNotificationDeleteParam = function (data) {
		return {
			grpid: data.grpid,
			fldid: data.fldid,
			dataid: data.dataid,
			alimseq: data.alimseq,
			alimType: data.type
		};
	};

	var removeNotificationList = function (notificationDeleteButton) {
		notificationDeleteButton.parent().remove();
		myNewsList.trigger('change');
	};

	var deleteButtonEventBinding = function (type) {
		myNewsList = $('#myNewsList');
		myNewsList.on('click', '.my-news__item__action', function (e) {
			e.preventDefault();

			var notificationDeleteButton = $(e.currentTarget);
			
			if (type === 'table') {
				cafe.notification.apiCaller.deleteTableActivityNotification(notificationDeleteButton.data().alimId)
					.done(removeNotificationList.bind(cafe.notification.notificationDeleter, notificationDeleteButton));
				return;
			}

			cafe.notification.apiCaller.deleteNotification(makeNotificationDeleteParam(notificationDeleteButton.data()))
				.done(removeNotificationList.bind(cafe.notification.notificationDeleter, notificationDeleteButton));
		});
	};

	var init = function (type) {
		deleteButtonEventBinding(type);
	};

	cafe.notification.notificationDeleter = (function () {
		return {
			init: init
		}
	})();
})(jQuery);

(function ($) {
	var PAGE_ITEM_SIZE = 20, PAGE_MIN_NUMBER = 1;
	var newsListWrap, newsList, paging;

	var cacheElements = function (options) {
		newsListWrap = $(options.newsListSelector);
		newsList = newsListWrap.find('li');
	};

	var presentFirstPageList = function () {
		newsList.eq(0).addClass('my-news__item--start');
		newsList.eq(PAGE_ITEM_SIZE).addClass('my-news__item--end');
	};

	var presentPageListBySelectedPage = function (e, page) {
		newsListWrap.find('.my-news__item--start').removeClass('my-news__item--start');
		newsListWrap.find('.my-news__item--end').removeClass('my-news__item--end');
		newsList.eq(page * 20).addClass('my-news__item--end');
		newsList.eq((page - 1) * 20).addClass('my-news__item--start');
	};

	var initTwbsPagination = function (pagingSelector) {
		var totalPages = Math.max(Math.ceil(newsList.length / PAGE_ITEM_SIZE), PAGE_MIN_NUMBER);

		paging = $(pagingSelector).twbsPagination({
			totalPages: totalPages,
			visiblePages: 5,
			first: '',
			last: '',
			prev: '<span class="ico-prev"><span class="sr_only">이전 리스트</span></span>',
			next: '<span class="ico-next"><span class="sr_only">다음 리스트</span></span>'
		}).on('page', presentPageListBySelectedPage);
	};

	var listPageChangeEventBinding = function () {
		newsListWrap.on('change', function () {
			newsList = newsListWrap.find('li');
			var currentPage = paging.twbsPagination('getCurrentPage');
			var changedCurrentPage = Math.max(Math.min(Math.ceil(newsList.length / PAGE_ITEM_SIZE), currentPage), PAGE_MIN_NUMBER);

			if (changedCurrentPage !== currentPage) {
				paging.twbsPagination('destroy');
				initTwbsPagination(paging.selector);
			}

			paging.twbsPagination('show', changedCurrentPage);
		});
	};

	cafe.notification.myAlimisPagination = (function () {
		return {
			init: function (options) {
				cacheElements(options);
				presentFirstPageList();
				initTwbsPagination(options.pagingSelector);
				listPageChangeEventBinding();
			}
		}
	})();
})(jQuery);

(function ($) {
	var linkToDaumAppPushSetting = function () {
		if (!Utils.isMobile()) {
			location.assign('http://search.daum.net/search?w=tot&DA=BESE&q=다음앱');
			return;
		}

		if (confirm('다음앱에서 카페 알림설정을 켜면 푸쉬 메세지를 받을 수 있습니다. 다음앱을 열겠습니까?')) {
			var scheme = 'https://apps.daum.net/scheme/daumapps%3A%2F%2Fopen%3Fcategory%3DpushSettings%26notiKey%3Dcafe%26personal%3D1';
			var intent = 'intent://open?category=pushSettings&notiKey=cafe&personal=1#Intent;scheme=daumapps;package=net.daum.android.daum;end';
			var landingUrl = Utils.isAndroid() ? intent : scheme;
			cafe.util.locationAssign(landingUrl);
		}
	};

	var setPushSettingLinkForDaumApp = function (selector) {
		$(selector).prop('href', 'daumglue://daum.settings/open?category=pushSettings&notiKey=cafe')
			.click(function (e) {
				if (typeof daumApps === 'object' && typeof daumApps.openPushSetting === 'function') {
					e.preventDefault();
					daumApps.openPushSetting(JSON.stringify({notiKey: 'cafe'}));
				}
			});
	};

	var setAppLinkForMobileWeb = function (selector) {
		$(selector).prop('href', 'javascript:;')
			.click(function () {
				linkToDaumAppPushSetting();
			});
	};

	cafe.daumapp = cafe.daumapp || {};
	cafe.daumapp.pushSettingLink = (function () {
		var init = function (selector) {
			if (cafe.util.isDaumApp()) {
				setPushSettingLinkForDaumApp(selector);
				return;
			}

			setAppLinkForMobileWeb(selector);
		};

		return {
			init: init
		}
	})();
})(jQuery);

(function () {
	function addEvent(evt, fn) {
		window.addEventListener
			? window.addEventListener(evt, fn, false)
			: window.attachEvent('on' + evt, fn);
	}

	addEvent('message', function (event) {
		// BARISTA-8867 팬매거진 게시글 인터랙션에서 로그인 시도시 event.data 에 'doActionLogin' 문자열이 들언다.
		if (event.data === "doActionLogin") {
			window.location.href = CAFEAPP.LOGIN_URL + '/accounts/loginform.do?mobilefull=1&url=' + document.URL;
		}
	});
})();

window.accountUtil = (function () {
	async function checkHasKakaoMailOrDaumMail(event) {
		if (!Utils.isLogin()) {
			return true;
		}

		const canSend = await hasKakaoMailOrDaumMail()
		if (!canSend) {
			alert("다음 또는 카카오 아이디(메일)가 있어야만 메일을 발송할 수 있습니다.");
			event.preventDefault();
		}
		return true;
	}

	function checkHasEmail(event) {
		if (!Utils.isLogin()) {
			return true;
		}
		if (!hasDaumId() && !hasEmail()) {
			alert("전화번호 계정은 \n카페 가입이 불가능합니다. \n카카오계정 관리에서 메일을 등록해주세요.");
			event.preventDefault();
		}
		return true;
	}

	function hasEmail() {
		var email = CAFEAPP.KAKAOEMAIL;
		if (!hasValue(email)) {
			return false;
		}

		return (email.indexOf('@') >= 0);
	}

	function hasDaumId() {
		return hasValue(CAFEAPP.DAUMID);
	}

	function hasKakaoMailOrDaumMail() {
		return new Promise((resolve, reject) => {
			$.ajax({
				type: 'GET',
				url: '/mail/sendavailability',
				async: false,
				success: function (result) {
					resolve(result.canSend)
				}
			});
		});
	}

	function hasValue(value) {
		return value != null && typeof value !== 'undefined' && value !== '';

	}

	return {
		checkHasEmail: checkHasEmail,
		checkHasKakaoMailOrDaumMail: checkHasKakaoMailOrDaumMail
	}
})();
